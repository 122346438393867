import { css } from 'styled-components';

const text = css`
  font-size: 14px;
  color: ${({ theme }) => theme.darkBlue};
`;

export const FontStyles = css`
  .light-text {
    ${text}
    font-family: Roboto Light;
  }

  .regular-text {
    ${text}
    font-family: Roboto Regular;
  }

  .normal-text {
    ${text}
    font-family: Roboto Regular;
    font-size: 16px;
  }

  .medium-text {
    ${text}
    font-family: Roboto Medium;
  }

  .bold-text {
    ${text}
    font-family: Roboto Bold;
  }

  .semibold-text {
    ${text}
    font-family: Roboto Semibold;
  }

  .black-text {
    ${text}
    font-family: Roboto Black;
  }

  .inter-100-text {
    ${text}
    font-family: Inter;
    font-weight: 100;
  }

  .inter-200-text {
    ${text}
    font-family: Inter;
    font-weight: 200;
  }

  .inter-300-text {
    ${text}
    font-family: Inter;
    font-weight: 300;
  }

  .inter-400-text {
    ${text}
    font-family: Inter;
    font-weight: 400;
  }

  .inter-500-text {
    ${text}
    font-family: Inter;
    font-weight: 500;
  }

  .inter-600-text {
    ${text}
    font-family: Inter;
    font-weight: 600;
  }

  .inter-700-text {
    ${text}
    font-family: Inter;
    font-weight: 700;
  }

  .inter-800-text {
    ${text}
    font-family: Inter;
    font-weight: 800;
  }

  .inter-900-text {
    ${text}
    font-family: Inter;
    font-weight: 900;
  }

  .primary-text {
    color: ${({ theme }) => theme.primaryBlue};
  }

  .grey-text-2 {
    color: ${({ theme }) => theme.greyText2};
  }

  .natural-50-text {
    color: ${({ theme }) => theme.natural_50};
  }

  .natural-200-text {
    color: ${({ theme }) => theme.natural_200};
  }

  .natural-300-text {
    color: ${({ theme }) => theme.natural_300};
  }

  .natural-400-text {
    color: ${({ theme }) => theme.natural_400};
  }

  .natural-500-text {
    color: ${({ theme }) => theme.natural_500};
  }

  .natural-700-text {
    color: ${({ theme }) => theme.natural_700};
  }

  .natural-900-text {
    color: ${({ theme }) => theme.natural_900};
  }

  .orange-500-text {
    color: ${({ theme }) => theme.orange_500};
  }

  .success-500-text {
    color: ${({ theme }) => theme.success_500};
  }

  .puple-500-text {
    color: ${({ theme }) => theme.purple_500};
  }

  .purple-50-text {
    color: ${({ theme }) => theme.purple_50};
  }

  .primary-500-text {
    color: ${({ theme }) => theme.primary_500};
  }

  .text-white {
    color: ${({ theme }) => theme.white};
  }

  .error-text {
    color: ${({ theme }) => theme.error_500};
  }
`;
