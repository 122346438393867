import React, { Fragment, useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { CSSTransition } from 'react-transition-group';
import { ModalContext } from '../context/ModalContext';
import { initModal } from '../helpers/utils';
import { ModalWrapper } from './modal.styled';
import ModifyConfig from './popup-components/modify-config';
import RequestSurvey from './popup-components/request-survey';
import SelectionInfo from './popup-components/selection-info';

const PopupComponents = {
  'modify-config': ModifyConfig,
  'selection-info': SelectionInfo,
  'request-survey': RequestSurvey,
};

const Modal = () => {
  const { setModal, modal } = useContext(ModalContext);
  const onClick = e => {
    if (e.target === e.currentTarget) {
      setModal(initModal);
    }
  };

  const ref = useOnclickOutside(
    () => {
      setModal(initModal);
    },
    {
      ignoreClass: `ignore-click`,
    },
  );

  const setPopupComponent = () => {
    const Component = PopupComponents[modal?.type];
    if (Component) {
      return <Component />;
    }
    return <Fragment />;
  };

  if (!modal?.type) return null;
  return (
    <CSSTransition appear classNames="modal-transition" timeout={100}>
      <ModalWrapper
        id="modal-wrapper-id"
        bottomMobileOpen={modal?.content?.bottomMobileOpen || false}
        onclick={onClick}
        style={{ justifyContent: modal.content?.top ? 'start' : 'center' }}>
        <CSSTransition key="modal-content-id" appear classNames="popup-in" in timeout={200}>
          <div
            key="modal-content-id"
            ref={ref}
            className="modal-content"
            id="modal-content-id"
            style={{
              borderRadius: modal.content?.noRadius ? '0' : '16px',
              marginTop: modal.content?.top ? '80px' : '0',
            }}>
            {setPopupComponent()}
          </div>
        </CSSTransition>
      </ModalWrapper>
    </CSSTransition>
  );
};

export default Modal;
