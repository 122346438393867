import { createSlice } from '@reduxjs/toolkit';
import api from '../services/index';

export const addressSlice = createSlice({
  name: 'address',
  initialState: {},
  reducers: {},
});

const getHeaders = api_key => {
  return { headers: { 'x-api-key': api_key } };
};

export const getAddressList = payload => async dispatch => {
  try {
    const { api_key } = payload;
    const { data } = await api.get(`/addresses`, { ...getHeaders(api_key), params: payload?.params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAddressDetails = payload => async dispatch => {
  try {
    const { api_key } = payload;
    const { data } = await api.get(`/addresses/${payload.id}`, getHeaders(api_key));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const {} = addressSlice.actions;
export default addressSlice.reducer;
